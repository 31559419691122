<template>
    <FinalField
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div :class="['form-field', $attrs['class']]">
                <label class="label">{{ label }}</label>

                <CounterInput
                    :class="inputClass"
                    :modelValue="props.value"
                    v-bind="{ ...getAttrs(props) }"
                />

                <FieldError :name="name" />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { omit } from 'lodash-es';
import { FinalField } from 'vue-final-form';
import CounterInput from '@/components/ui/CounterInput';
import FieldError from '@/components/form/FieldError';

export default {
    components: { CounterInput, FinalField, FieldError },

    inheritAttrs: false,

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        editMode: {
            type: Boolean,
            default: true,
        },

        inputClass: {
            type: String,
        },
    },

    methods: {
        getAttrs(props) {
            const attrs = omit(this.$attrs, 'class');

            if (this.editMode) {
                return {
                    ...attrs,
                    readOnly: false,
                    name: props.name,
                    onFocus: props.events.focus,
                    onBlur: props.events.blur,
                    'onUpdate:modelValue': val => props.change(val),
                };
            }

            return {
                ...attrs,
                readOnly: true,
            };
        },
    },
};
</script>
