<template>
    <div class="counter">
        <template v-if="!readOnly">
            <div class="counter__input">
                <AmountInput
                    class="flex-grow"
                    amountClass="rounded-r-none"
                    :precision="0"
                    :computeWidth="false"
                    v-bind="{
                        modelValue,
                        min,
                        max,
                        disabled,
                    }"
                    v-on="fieldFocusEvents"
                />

                <button
                    type="button"
                    class="counter__btn"
                    :disabled="disabled || modelValue <= min"
                    @click="$emit('update:modelValue', modelValue - 1)"
                >
                    <icon
                        name="minus"
                        class="counter__btn-icon"
                    />
                </button>

                <button
                    type="button"
                    class="counter__btn rounded-r-lg"
                    :disabled="disabled || modelValue >= max"
                    @click="$emit('update:modelValue', modelValue + 1)"
                >
                    <icon
                        name="plus"
                        class="counter__btn-icon"
                    />
                </button>
            </div>
            <div class="counter__hint">{{ hint }}</div>
        </template>

        <template v-else>{{ modelValue }}</template>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import AmountInput from '@/components/ui/AmountInput';

export default {
    components: { Icon, AmountInput },

    props: {
        /**
         * Counter value
         */
        modelValue: {
            type: Number,
        },

        /**
         * Min availbale value
         */
        min: {
            type: Number,
            default: 1,
        },

        /**
         * Max available value
         */
        max: {
            type: Number,
            required: false,
        },

        /**
         * If the control should be disabled
         */
        disabled: {
            type: Boolean,
        },

        /**
         * Whether input is readonly
         */
        readOnly: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        /**
         * 	Emitted on value change
         */
        'update:modelValue',
        /**
         * Emitted on focus
         */
        'focus',
        /**
         * Emitted on blur
         */
        'blur',
    ],

    computed: {
        hint() {
            const min = this.min ?? 'not limited';
            const max = this.max ?? 'not limited';

            return `min ${min} - max ${max}`;
        },

        fieldFocusEvents() {
            return {
                blur: () => {
                    this.$emit('blur');
                },

                focus: () => {
                    this.$emit('focus');
                },

                'update:modelValue': value => {
                    this.$emit('update:modelValue', value);
                },
            };
        },
    },
};
</script>

<style scoped>
.counter__input {
    @apply flex w-full;
}

.counter__btn {
    @apply w-14 h-14 flex items-center border-t border-r border-b border-gray-250 justify-center bg-white text-purple-600;
    min-width: 3.5rem;
    /* disable double-tap to zoom */
    touch-action: manipulation;
}

.counter__btn:disabled {
    @apply text-white bg-gray-300;
}

.counter__btn-icon :deep(svg) {
    stroke-width: 1.5;
}

.counter__btn-icon {
    @apply w-4 h-4;
}

.counter__hint {
    @apply text-xs font-ffdin font-medium text-black tracking-wide mt-1;
}
</style>
