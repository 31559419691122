<template>
    <div
        class="datetime"
        :class="{ 'datetime--adaptive': timeEditMode }"
    >
        <DateInput
            class="datepicker"
            :placeholder="datePlaceholder"
            :modelValue="dateValue"
            position="left"
            v-bind="{
                disabled,
                minDate,
                maxDate,
                allowedDates,
                format,
                monthYear,
                monthNameFormat,
            }"
            clearable
            autoApply
            @update:modelValue="handleDateChange"
            @updateMonthYear="val => $emit('updateMonthYear', val)"
            @clear="clearDate"
            @open="$emit('date-open')"
            @close="$emit('date-close')"
        />

        <TimeInput
            v-if="timeControlVisible"
            ref="timeInput"
            :modelValue="timeValue"
            :placeholder="timePlaceholder"
            :disabled="timeDisabled || disabled"
            :options="options"
            :readOnly="!timeEditMode"
            :inputEditable="timeInputEditable"
            @update:modelValue="handleTimeChange"
            @cleared="clearTime"
        />
    </div>
</template>

<script>
import DateInput from '@/components/ui/DateInput';
import TimeInput from '@/components/ui/TimeInput';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import moment from 'moment-timezone';

export default {
    components: { TimeInput, DateInput },

    mixins: [CommunityTimezoneMixin],

    props: {
        /**
         * component's v-model
         */
        modelValue: {
            type: [String, Date],
            required: false,
            default: null,
        },

        /**
         * Disabled state
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Time input edit mode
         */
        timeEditMode: {
            type: Boolean,
            default: true,
        },

        /**
         * All dates before the given date will be disabled
         */
        minDate: {
            type: String,
            default: null,
        },

        /**
         * All dates after the given date will be disabled
         */
        maxDate: {
            type: String,
            default: null,
        },

        /**
         * Allow only specific dates
         */
        allowedDates: {
            type: Array,
            default: () => [],
        },

        /**
         * Format the value of the date(s) in the input field
         */
        format: {
            type: String,
            default: 'MM/dd/yyyy',
        },

        /**
         * Formats value before saving to the form state
         */
        formatter: {
            type: Function,
            default: value => value,
        },

        /**
         * Date in community timezone
         */
        inCommunityTimezone: {
            type: Boolean,
            default: true,
        },

        monthYear: {
            type: Object,
            default: null,
        },

        monthNameFormat: {
            type: String,
            default: 'short',
        },

        /**
         * Placeholder text for date input
         */
        datePlaceholder: {
            type: String,
            default: 'Date',
        },

        /**
         * Placeholder text for time input
         */
        timePlaceholder: {
            type: String,
            required: false,
            default: '',
        },

        /**
         * Add options dropdown
         */
        options: {
            type: Array,
            required: false,
            default: () => [],
        },

        /**
         * Disable time input
         */
        timeDisabled: {
            type: Boolean,
        },

        /**
         * Allows time input typing
         */
        timeInputEditable: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['update:modelValue', 'updateMonthYear', 'date-open', 'date-clear', 'updateDate', 'date-close'],

    data() {
        return {
            dateValue: undefined,
            timeValue: undefined,
        };
    },

    computed: {
        isValueComplete() {
            return this.dateValue && this.timeValue;
        },

        isEmpty() {
            return !this.dateValue && !this.timeValue;
        },
    },

    watch: {
        modelValue: {
            handler(value) {
                if (!value) {
                    return;
                }

                const momentObject = this.inCommunityTimezone ? this.parseDateTime(value) : moment(value);

                if (!momentObject.isValid()) {
                    if (!this.isEmpty) {
                        this.clear();
                    }

                    return;
                }

                if (this.timeValue?.hours !== momentObject.hours() || this.timeValue?.minutes !== momentObject.minutes()) {
                    this.timeValue = {
                        hours: momentObject.hours(),
                        minutes: momentObject.minutes(),
                    };
                }

                if (this.dateValue?.toISOString() !== momentObject.clone().startOf('day').toISOString()) {
                    this.dateValue = momentObject.startOf('day');
                }
            },

            immediate: true,
        },
    },

    methods: {
        handleDateChange(value) {
            let newDateValue = this.inCommunityTimezone ? this.parseDateTime(value, true) : moment(value);
            newDateValue = newDateValue.startOf('day');

            if (this.dateValue?.toISOString() !== newDateValue?.toISOString()) {
                this.dateValue = newDateValue;

                this.$emit('updateDate', newDateValue.format('YYYY-MM-DD'));

                this.emitValue();
            }
        },

        handleTimeChange(value) {
            this.timeValue = value;

            this.emitValue();
        },

        // Emits full value (i.e date and time).
        emitValue() {
            if (!this.isValueComplete) {
                this.$emit('update:modelValue', null);
            } else {
                const dateTime = this.dateValue.clone().hour(this.timeValue.hours).minute(this.timeValue.minutes);

                const parsedDateTime = this.inCommunityTimezone ? this.parseDateTime(dateTime, true).toISOString() : dateTime.toISOString();

                const formattedDateTime = this.formatter(parsedDateTime);

                this.$emit('update:modelValue', formattedDateTime);
            }
        },

        clear() {
            this.clearDate();
            this.clearTime();
        },

        clearDate() {
            this.dateValue = undefined;
            this.$emit('updateDate', null);
            this.emitValue();
        },

        clearTime() {
            this.timeValue = undefined;
        },
    },
};
</script>

<style scoped>
.datetime .datepicker {
    @apply relative;
}

.datetime:not(.datetime--adaptive) {
    @apply flex;
}

.datetime:not(.datetime--adaptive) .datepicker {
    @apply flex-grow;
}

.datetime:not(.datetime--adaptive) .time-input {
    @apply ml-4;
}

.datetime--adaptive .time-input {
    @apply mt-2;
}

@media (min-width: 350px) {
    .datetime--adaptive {
        @apply flex;
    }

    .datetime--adaptive .time-input {
        @apply mt-0 ml-2;
        flex-basis: 47%;
    }

    .datetime--adaptive .datepicker {
        flex-basis: 53%;
    }
}
</style>
