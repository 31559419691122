<template>
    <div
        class="time-input"
        :class="{
            'time-input--noneditable': !inputEditable,
            'time-input--opened': opened,
            'time-input--disabled': disabled,
        }"
    >
        <div
            v-if="readOnly"
            class="time-input__text"
        >
            {{ innerValue }}
        </div>

        <template v-else>
            <div
                class="relative w-full"
                @click="toggleDropdown"
            >
                <the-mask
                    ref="field"
                    type="text"
                    class="input"
                    mask="##:D# Tm"
                    :tokens="maskTokens"
                    :value="innerValue"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    masked
                    v-on="fieldEvents"
                />

                <div
                    v-if="!inputEditable"
                    class="time-input__overlay"
                ></div>

                <button
                    v-if="innerValue && clearable"
                    type="button"
                    class="time-input__reset"
                    @click.stop="clearValue"
                >
                    <Icon
                        name="close"
                        class="w-6 h-6"
                    />
                </button>

                <Icon
                    v-else
                    name="clock"
                    class="time-input__clock"
                />
            </div>

            <div
                v-if="opened && hasOptions"
                class="time-input__dropdown-menu"
            >
                <div
                    v-for="({ key, value }, index) in options"
                    v-bind:key="key"
                    class="time-input__dropdown-item"
                    :class="{
                        'time-input__dropdown-item--active': currentItem === index,
                        'time-input__dropdown-item--selected': selectedOption === index,
                    }"
                    @mouseover="handleItemMouseOver(index)"
                    @click="selectOption(key)"
                >
                    {{ value }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import Icon from '@/components/ui/Icon';

const _prepareObject = timeString => {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':');

    if (period === 'am') {
        return {
            hours: hours === '12' ? 0 : Number.parseInt(hours),
            minutes: Number.parseInt(minutes),
        };
    }

    return {
        hours: hours === '12' ? Number.parseInt(hours) : Number.parseInt(hours) + 12,
        minutes: Number.parseInt(minutes),
    };
};

export default {
    components: { TheMask, Icon },

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        /**
         * component's v-model
         */
        modelValue: {
            required: false,
            default: null,
        },

        /**
         * Add a clear icon to the input field
         */
        clearable: {
            type: Boolean,
            default: true,
        },

        /**
         * Disables the input
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Input placeholder
         */
        placeholder: {
            type: String,
            default: 'Time',
        },

        /**
         * Whether input is readonly
         */
        readOnly: {
            type: Boolean,
        },

        /**
         * Input dropdown options [{key, value}]
         */
        options: {
            type: Array,
            required: false,
        },

        /**
         * Allows input typing
         */
        inputEditable: {
            type: Boolean,
            default: true,
        },

        maskTokens: {
            type: Object,
            default: () => ({
                '#': {
                    pattern: /\d/,
                },

                D: {
                    pattern: /[0-5]/,
                },

                T: {
                    pattern: /[a|p]/,
                    transform: v => v.toLocaleLowerCase(),
                },
            }),
        },
    },

    emits: [
        /**
         * Emitted on change
         */
        'update:modelValue',
        /**
         * Emitted on clear
         */
        'cleared',
    ],

    data() {
        return {
            innerValue: '',
            focused: false,
            opened: false,
            currentItem: null,
            selectedOption: null,
        };
    },

    computed: {
        isValueComplete() {
            return this.innerValue.length === 8;
        },

        hasOptions() {
            return Array.isArray(this.options) && this.options.length;
        },

        fieldEvents() {
            return {
                input: value => {
                    if (typeof value !== 'string') {
                        return;
                    }

                    this.innerValue = value;
                },

                focus: () => (this.focused = true),
                blur: () => (this.focused = false),
            };
        },
    },

    watch: {
        modelValue: {
            handler(value) {
                if (
                    // eslint-disable-next-line no-prototype-builtins
                    value?.hasOwnProperty('hours') &&
                    // eslint-disable-next-line no-prototype-builtins
                    value?.hasOwnProperty('minutes')
                ) {
                    const hours = value.hours % 12 || 12;
                    const period = value.hours >= 12 ? 'pm' : 'am';
                    this.innerValue = `${String(hours).padStart(2, '0')}:${String(value.minutes).padStart(2, '0')} ${period}`;
                } else {
                    this.innerValue = '';
                }
            },

            immediate: true,
            deep: true,
        },

        innerValue(val) {
            if (this.isValueComplete) {
                this.$emit('update:modelValue', _prepareObject(val));
            } else if (!this.focused) {
                this.$emit('update:modelValue', null);
            }

            // Add leading zero if first number is not 1
            if (val.length === 1 && Number.parseInt(val) > 1) {
                this.innerValue = `0${val}`;
                setTimeout(() => {
                    this.$refs.field.$el.setSelectionRange(this.innerValue.length, this.innerValue.length);
                });
            } else if (val === '00') {
                this.innerValue = '12';
            }
        },

        focused(isFocused) {
            if (!isFocused && !this.isValueComplete) {
                this.$emit('update:modelValue', null);
            }
        },

        opened(value) {
            this.$nextTick(() => {
                if (value) {
                    this.addEventHandlers();
                } else {
                    this.removeEventHandlers();
                }
            });
        },
    },

    beforeUnmount() {
        this.removeEventHandlers();
    },

    methods: {
        toggleDropdown() {
            if (this.disabled) {
                return;
            }

            if (this.opened) {
                this.closeDropdown();
            } else {
                this.openDropdown();
            }
        },

        clearValue() {
            this.innerValue = '';
            this.selectedOption = null;
            this.$emit('cleared');
        },

        handleItemMouseOver(index) {
            this.currentItem = index;
        },

        selectOption(key) {
            const selectedIndex = this.options.findIndex(option => option.key === key);

            this.selectedOption = selectedIndex;
            this.innerValue = key;

            this.closeDropdown();
        },

        openDropdown() {
            if (this.disabled) {
                return;
            }

            this.opened = true;
        },

        closeDropdown() {
            this.opened = false;
            this.currentItem = null;
        },

        handleClickOutside(e) {
            if (this.$el.contains(e.target)) {
                return;
            }

            this.closeDropdown();
        },

        addEventHandlers() {
            document.addEventListener('click', this.handleClickOutside);
        },

        removeEventHandlers() {
            document.removeEventListener('click', this.handleClickOutside);
        },
    },
};
</script>

<style scoped>
.time-input {
    @apply flex items-center relative;
}

.time-input__text {
    @apply text-black text-xl min-w-max;
}

.time-input__overlay {
    @apply absolute left-0 top-0 w-full h-full cursor-pointer;
}

.time-input--disabled .time-input__overlay {
    @apply cursor-default;
}

.time-input__dropdown-menu {
    @apply absolute left-0 top-0 z-50 border border-gray-250 bg-white rounded-lg h-auto overflow-auto overscroll-contain;
    max-height: 8rem;
    min-width: 100%;
    transform: translate(0, 4rem);
}

.time-input__dropdown-item {
    @apply flex items-center py-3 px-6 cursor-pointer font-sofia font-medium text-md;
    min-height: 2.25rem;
}

.time-input__dropdown-item--selected,
.time-input__dropdown-item--active {
    @apply text-white bg-purple-600;
}

.time-input--noneditable :deep(.input:not(:disabled)) {
    @apply bg-white;
}

.input {
    @apply pr-10;
}

.time-input__reset {
    @apply absolute flex items-center right-0 top-0 bottom-0 px-3 text-gray-450;
}

.time-input__clock {
    @apply absolute w-6 h-6 text-gray-450;
    transform: translateY(-50%);
    right: 13px;
    top: 50%;
}

.time-input--disabled .time-input__clock {
    @apply text-gray-400;
}
</style>
