<template>
    <Form
        ref="form"
        class="h-full"
        :initial-values="initialValues"
        :submit="handleSubmit"
        :validate="validate"
        @change.self="handleFormChange"
    >
        <template v-slot="props">
            <Page
                v-if="rentableItem"
                :title="$t('ri.reservations.create.title')"
                custom-back-button
                :show-back-button="prevRoute === 'rentableItems.details'"
                @back="() => this.$router.back()"
            >
                <template #default>
                    <MessageBox
                        leadingIcon="info"
                        class="mb-4"
                        size="small"
                    >
                        {{ createInfo }}
                    </MessageBox>

                    <div class="mb-8">
                        <DateTimeField
                            ref="dateTimeInput"
                            name="startTime"
                            :label="`${$t('ri.reservations.fields.start_date')}*`"
                            :validate="required"
                            :suppressError="isStartAvailabilityErrorVisible(props) || isStartTimeAvailabilityErrorVisible(props)"
                            :in-community-timezone="false"
                            :formatter="getFormattedDate"
                            :allowedDates="allowedDates"
                            :monthYear="monthYearPicker"
                            monthNameFormat="long"
                            timePlaceholder="Time"
                            :options="availableTime"
                            :timeDisabled="!this.formValues.start"
                            :timeEditMode="hasTimeInput"
                            :timeInputEditable="false"
                            @dateOpen="fetchAvailableDates"
                            @dateClose="resetMonthYear"
                            @updateDate="updateStartDate"
                            @updateMonthYear="updateMonthYear"
                        />
                        <FinalField name="startAvailability">
                            <template v-slot="{ name }">
                                <FieldError :name="name" />
                            </template>
                        </FinalField>

                        <FinalField name="startTimeAvailability">
                            <template v-slot="{ name }">
                                <FieldError :name="name" />
                            </template>
                        </FinalField>
                    </div>

                    <CounterField
                        :label="durationLabel"
                        name="duration"
                        :max="maxDuration"
                        :validate="composeValidators(required, minValue(1))"
                        :disabled="durationDisabled"
                    />

                    <div
                        v-if="props.errors.restrictedDuration"
                        class="field-error mb-8"
                    >
                        {{ props.errors.restrictedDuration }}
                    </div>

                    <MessageBox
                        v-if="this.riTimeslot === TIMESLOT.DAY && endDateReduced"
                        class="my-2"
                        theme="warning"
                        leadingIcon="warning"
                        size="small"
                        rounded
                    >
                        {{ reducedReservationPeriod }}
                    </MessageBox>

                    <div class="form-field form-field--offset-l">
                        <label class="label">{{ $t('ri.reservations.fields.end_date') }}</label>
                        <div
                            class="text-xl"
                            :class="{ 'text-gray-450': !formValues.end }"
                        >
                            {{ endDateTime }}
                        </div>
                    </div>

                    <SelectField
                        name="itemIds"
                        labelKey="name"
                        :searchPlaceholder="itemsSearchPlaceholder"
                        :label="itemsLabel"
                        :buttonLabel="itemsButtonLabel"
                        :validate="notEmpty"
                        :dataProvider="$riDataProvider"
                        resource="items"
                        :requestParams="itemsRequestParams"
                        :lazy="true"
                        :multiple="true"
                        :objectMode="true"
                        :clearable="false"
                        :disabled="itemsDisabled"
                        :suppressError="isItemsVisibilityErrorVisible(props)"
                        @optionsLoaded="handleItemsLoad"
                        @adaptiveStateUpdated="selectAdaptive = $event"
                    >
                        <template #slide-panel-header="props">
                            <div
                                v-if="props.options.length"
                                class="text-xs font-medium font-ffdin text-gray-500 my-2"
                            >
                                {{ availableLabel }}:
                            </div>
                        </template>

                        <template #adaptive-header>
                            <FieldError
                                class="mb-2"
                                name="itemsAvailability"
                            />
                        </template>

                        <template #selected-label="{ count }">
                            {{ getSelectedItemsLabel(count) }}
                        </template>
                    </SelectField>

                    <FieldError
                        v-if="!selectAdaptive"
                        name="itemsAvailability"
                    />

                    <div class="grid grid-cols-2 form-field">
                        <div class="flex flex-col">
                            <label class="label">{{ $t('ri.deposit.price_per', { label: riItemsLabel }) }}:</label>
                            <span>
                                {{ currencyFormatter(rentableItem.timeslot.rate) }}
                            </span>
                        </div>
                        <div class="flex flex-col">
                            <label class="label">{{ $t('ri.deposit.amount') }}:</label>
                            <span>
                                {{ currencyFormatter(rentableItem.deposit?.amount ?? 0) }}
                            </span>
                        </div>
                    </div>
                </template>

                <template #footer>
                    <div class="p-6 bg-purple-300">
                        <div class="form-field">
                            <div class="mb-4">
                                <div class="flex justify-between text-sm mb-1">
                                    <span>{{ totalLabel }}</span>
                                    <span>{{ currencyFormatter(rentableItem.timeslot.rate) }}</span>
                                </div>
                                <div class="flex justify-between text-sm">
                                    <span>{{ $t('ri.deposit.deposit') }}:</span>
                                    <span>{{ currencyFormatter(rentableItem.deposit?.amount ?? 0) }}</span>
                                </div>
                            </div>
                            <div class="flex justify-between mb-3 text-xl">
                                <span>{{ $t('ri.reservations.create.to_be_paid') }}</span>
                                <span>{{ totalValue }}</span>
                            </div>
                            <button
                                class="btn btn-primary w-full"
                                :disabled="!isFormValid(props)"
                            >
                                {{ $t('ri.reservations.create.save_reservation') }}
                            </button>
                        </div>
                    </div>
                </template>
            </Page>
        </template>
    </Form>
</template>

<script>
import moment from 'moment-timezone';
import { mapActions } from 'vuex';
import { isEqual, pick, debounce, upperFirst } from 'lodash-es';
import { FinalField } from 'vue-final-form';
import { useI18n } from 'vue-i18n';
import { mask } from '@/utils/Amount';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import Page from '@/components/ui/Page';
import MessageBox from '@/components/ui/MessageBox';
import Form from '@/components/form/Form';
import DateTimeField from '@/components/form/DateTimeField';
import CounterField from '@/components/form/CounterField';
import SelectField from '@/components/form/SelectField';
import TabbedMonthYearPicker from '@/components/ui/date-input/TabbedMonthYearPicker';
import FieldError from '@/components/form/FieldError';
import { TIMESLOT } from '@/views/rentableItems/constants';
import { currencyFormatter } from '@/utils/helpers';

export default {
    components: {
        MessageBox,
        Page,
        Form,
        DateTimeField,
        CounterField,
        SelectField,
        FieldError,
        FinalField,
    },

    mixins: [ConfirmationMixin, NotifyMixin, ValidatorMixin],

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from.name;
        });
    },

    async beforeRouteLeave(to, from, next) {
        const { pristine } = this.$refs.form.finalForm.getState();

        const result =
            this.submitted ||
            pristine ||
            (await this.requestConfirmation({
                text: this.t('ri.reservations.confirmation.create.text'),
                cancelCaption: this.t('ri.reservations.confirmation.create.cancel_caption'),
                confirmCaption: this.t('ri.reservations.confirmation.create.confirm_caption'),
            }));

        next(result);
    },

    setup() {
        const { t } = useI18n();

        return { t };
    },

    data: () => ({
        initialValues: {
            start: null,
            startTime: null,
            end: null,
            duration: 1,
            qty: 0,
            itemIds: [], // TODO: Rename to items since it's using object mode, is that object mode needed?
            total: null,
            // advanced validation
            startAvailability: null,
            startTimeAvailability: null,
            itemsAvailability: [],
        },

        formValues: {},
        submitted: false,
        selectAdaptive: false,
        endDateReduced: false,
        itemsDisabled: false,

        loading: false,
        prevRoute: null,
        rentableItem: null,

        availableDates: [],
        availableDateTimes: [],
        сalendarActiveMonthYear: { month: 0, year: 0 },
        itemsTotal: 0,
        itemsRequestParams: {
            riId: null,
            availableFrom: null,
            availableTo: null,
        },
    }),

    computed: {
        monthYearPicker() {
            return TabbedMonthYearPicker;
        },

        rentableItemId() {
            return this.$route.params.riId;
        },

        riTimeslot() {
            return this.rentableItem?.timeslot?.unit;
        },

        finalForm() {
            return this.$refs.form.finalForm;
        },

        totalLabel() {
            const { qty, duration } = this.formValues;
            const qtyLabel = mask(qty, { precision: 0 });
            const itemsLabel = this.riItemsLabel;
            const durationLabel = mask(duration, {
                precision: 0,
            });
            const timeslotLabel = this.getTimeslotLabel(duration);

            return `${qtyLabel} ${itemsLabel} х ${durationLabel} ${timeslotLabel}`;
        },

        riItemsLabel() {
            const { qty } = this.formValues;
            return this.t('ri.item', qty);
        },

        durationWithTimeslotLabel() {
            const { duration } = this.formValues;
            const timeslotLabel = this.getTimeslotLabel(duration);

            return `${duration} ${timeslotLabel}`;
        },

        totalValue() {
            const { qty, duration } = this.formValues;
            return currencyFormatter((this.rentableItem.deposit?.amount ?? 0) + this.rentableItem.timeslot.rate * qty * duration);
        },

        startTime() {
            const { start } = this.formValues;

            return start ? moment(start).format('hh:mm\xA0a') : start;
        },

        endDateTime() {
            const { end } = this.formValues;

            return end ? moment(end).format('MM/DD/YYYY, hh:mm\xA0a') : this.t('ri.reservations.fields.not_specified');
        },

        allowedDates() {
            if (!this.availableDates.length) {
                return [null];
            }

            return this.availableDates;
        },

        itemsLabel() {
            if (this.itemsDisabled) {
                return this.t('ri.reservations.fields.select_time');
            }

            if (!this.selectAdaptive) {
                return `${this.availableLabel}*`;
            }

            return `${this.selectItemsLabel}*`;
        },

        itemsButtonLabel() {
            if (this.selectAdaptive && !this.itemsDisabled) {
                return this.availableLabel;
            }

            return this.selectItemsLabel;
        },

        availableLabel() {
            return `${this.itemsTotal} ${this.t('ri.item', this.itemsTotal)} ${this.t('ri.reservations.fields.available')}`;
        },

        selectItemsLabel() {
            return this.t('ri.reservations.fields.items', { riType: this.t('ri.item', 2) });
        },

        durationLabel() {
            return this.t('ri.reservations.fields.duration_timeslot', { timeslot: this.getTimeslotLabel(0) });
        },

        durationDisabled() {
            return !this.formValues.start && !this.formValues.qty;
        },

        maxDuration() {
            // prettier-ignore
            switch (this.riTimeslot) {
            case this.TIMESLOT.HOUR:
                return 24;
            case this.TIMESLOT.MONTH:
                return 120;
            default:
                return undefined;
            }
        },

        availableTime() {
            return this.availableDateTimes.map(({ start }) => {
                const time = moment(start).format('hh:mm a');

                return {
                    key: time,
                    value: time,
                };
            });
        },

        hasTimeInput() {
            // prettier-ignore
            switch (this.riTimeslot) {
            case this.TIMESLOT.HOUR:
            case this.TIMESLOT.DAY:
                return true;
            default:
                return false;
            }
        },

        startDateIncomplete() {
            return this.formValues.start && !this.formValues.startTime;
        },

        itemsSearchPlaceholder() {
            return this.t('ri.reservations.create.search_by_item', { riType: this.t('ri.item') });
        },

        reducedReservationPeriod() {
            const selectedItemsCount = this.formValues.itemIds.length || this.itemsTotal;
            const riTypeLabel = this.t('ri.item', selectedItemsCount);

            return this.t('ri.reservations.create.reduced_reservation_period', {
                period: this.durationWithTimeslotLabel,
                selectedRiType: riTypeLabel,
                riType: upperFirst(riTypeLabel),
            });
        },

        createInfo() {
            return this.t('ri.reservations.messages.create.info', { riType: this.t('ri.item', 2) });
        },
    },

    created() {
        this.formValues = this.initialValues;
        this.loading = true;
        this.setItemsParams({ riId: this.rentableItemId });
        this.TIMESLOT = TIMESLOT;

        this.fetchRentableItem()
            .then(() => {
                this.initializeWatchers();
            })
            .catch(error => this.notifyError(error.message))
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        currencyFormatter,

        ...mapActions({
            setCreatedReservationsCount: 'ri/setCreatedReservationsCount',
        }),

        validate({ startTime, end }) {
            return {
                restrictedDuration: startTime && !end ? this.t('ri.reservations.create.restricted_duration') : undefined,
            };
        },

        isEndReduced({ startTime, duration, end }) {
            if (!startTime || !end) {
                return false;
            }

            const computedEnd = moment(startTime).add(duration, 'days');

            return moment(end).isBefore(computedEnd, 'day');
        },

        updateMonthYear({ month, year }) {
            if (month === this.сalendarActiveMonthYear.month && year === this.сalendarActiveMonthYear.year) {
                return;
            }

            this.сalendarActiveMonthYear = { year, month };

            this.availableDates = [];
        },

        resetMonthYear() {
            this.updateMonthYear({ month: 0, year: 0 });
        },

        handleItemsLoad(totalSize) {
            if (!this.startDateIncomplete) {
                this.itemsTotal = totalSize;
            }

            if (!this.startDateIncomplete && this.itemsDisabled) {
                this.itemsDisabled = false;
            }
        },

        updateEndDate({ startTime = null, duration = this.formValues.duration }) {
            const end = this.getAvailableDateTimeByStart(startTime)?.end || null;

            this.endDateReduced = this.isEndReduced({ startTime, duration, end });

            if (this.formValues.end !== end) {
                this.finalForm.change('end', end);
            }
        },

        resetDuration() {
            this.finalForm.change('duration', 1);
        },

        setItemsParams(params) {
            if (Object.hasOwn(params, 'availableFrom')) {
                this.itemsRequestParams.availableFrom = params.availableFrom;
            }

            if (Object.hasOwn(params, 'availableTo')) {
                this.itemsRequestParams.availableTo = params.availableTo;
            }

            if (Object.hasOwn(params, 'riId')) {
                this.itemsRequestParams.riId = params.riId;
            }
        },

        getFormattedDate(date) {
            const formatTemplate = 'YYYY-MM-DDTHH:mm:ss';

            if (date) {
                return moment.utc(date).format(formatTemplate);
            }

            return moment().format(formatTemplate);
        },

        getSelectedItemsLabel(count) {
            return this.itemsDisabled
                ? this.t('ri.reservations.fields.select_time')
                : `${this.t('select.selected', { count, itemType: this.t('ri.item', count) })}:`;
        },

        fetchRentableItem() {
            return this.$riDataProvider.getOne('rentableItems', { id: this.rentableItemId }).then(rentableItem => {
                this.rentableItem = rentableItem;
            });
        },

        fetchAvailableDates(values) {
            const { itemIds, duration, start } = { ...this.formValues, ...values };

            const data = {
                riId: this.rentableItem.id,
                duration,
            };

            if (Array.isArray(itemIds) && itemIds.length) {
                data.itemsIds = itemIds.map(({ id }) => id).join(',');
            }

            if (start) {
                data.datetime = this.getFormattedDate(start);
            }

            return this.$riDataProvider.availableDays('reservations', data).then(availableDates => {
                this.availableDates = availableDates;
            });
        },

        fetchAvailableDateTimes() {
            const { start: date, duration, itemIds } = this.formValues;
            const data = {
                riId: this.rentableItemId,
                date,
                duration,
            };

            if (Array.isArray(itemIds) && itemIds.length) {
                data.itemsIds = itemIds.map(({ id }) => id).join(',');
            }

            return this.$riDataProvider.availableTime('reservations', data).then(availableDateTimes => {
                this.availableDateTimes = availableDateTimes;
            });
        },

        handleFormChange({ values }) {
            this.formValues = values;
        },

        getAvailableDateTimeByStart(dateTime) {
            return this.availableDateTimes.find(({ start }) => start === dateTime) || null;
        },

        isFormValid(props) {
            if (!props.submitErrors) {
                return props.valid;
            }

            const submitErrorsKeys = Object.keys(props.submitErrors);
            const availabilityErrorKeys = ['itemsAvailability', 'startAvailability', 'startTimeAvailability'];
            const hasAvailabilityErrors = submitErrorsKeys.every(errorKey => availabilityErrorKeys.includes(errorKey));

            return props.valid || (!props.hasValidationErrors && hasAvailabilityErrors);
        },

        async handleSubmit(values) {
            const data = {
                ...pick(values, ['end', 'duration', 'qty']),
                itemsIds: values.itemIds.map(({ id }) => id),
                start: values.startTime,
                ...(this.rentableItem.deposit && {
                    // TODO: Add field when handling deposits US
                    depositPayment: {
                        type: 'CREDIT_CARD',
                    },
                }),
            };

            try {
                const errors = await this.validateReservation(data);

                if (errors) {
                    return errors;
                }

                await this.$riDataProvider.create('reservations', { riId: this.rentableItem.id, data });
                this.submitted = true;

                const createdReservationsCount = data.qty;
                this.setCreatedReservationsCount(createdReservationsCount);
                this.$router.replace({ name: 'rentableItems.index', hash: '#reservations' });
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },

        async validateReservation(data) {
            const { isDateRangeValid, availableItems, availableCalendarPeriods } = await this.$riDataProvider.validate('reservations', {
                riId: this.rentableItemId,
                data,
            });

            const preparedAvailablePeriods = this.hasTimeInput
                ? availableCalendarPeriods
                : availableCalendarPeriods.map(({ start }) => moment(start).format('YYYY-MM-DD'));

            const startDateTime = data.start;

            let errors = null;
            const items = this.formValues.itemIds;
            const itemsCount = items.length;

            if (!isDateRangeValid) {
                if (this.hasTimeInput) {
                    if (preparedAvailablePeriods.length) {
                        const formattedStartTime = moment(startDateTime).format('hh:mm a');
                        this.updateStartTimeAvailability(preparedAvailablePeriods);

                        errors = Object.assign({}, errors, {
                            startTimeAvailability: this.validStartDateTime(formattedStartTime, isDateRangeValid),
                        });
                    } else {
                        await this.fetchAvailableDates();
                        const selectedDayAvailable = this.availableDates.findIndex(date => date === this.formValues.start) !== -1;

                        if (!selectedDayAvailable) {
                            const formattedStartDate = moment(startDateTime).format('LL');
                            this.updateStartDateAvailability();

                            errors = Object.assign({}, errors, {
                                startAvailability: this.validStartDate(formattedStartDate, isDateRangeValid),
                            });
                        }
                    }
                } else {
                    const formattedStartDate = moment(startDateTime).format('LL');
                    this.updateStartDateAvailability(preparedAvailablePeriods);

                    errors = Object.assign({}, errors, {
                        startAvailability: this.validStartDate(formattedStartDate, isDateRangeValid),
                    });
                }
            } else if (availableItems.count !== itemsCount) {
                this.updateItemsAvailability(items, availableItems);
                errors = { itemsAvailability: this.validItems(items, availableItems) };
            }

            return errors;
        },

        updateStartDateAvailability(availableDates) {
            this.unwatchStartAvailability();
            this.unwatchStartAvailability = null;

            this.finalForm.change('start', null);
            this.finalForm.change('startTime', null);

            if (Array.isArray(availableDates)) {
                this.availableDates = availableDates;
            }
        },

        updateStartTimeAvailability(availableDateTimes) {
            this.unwatchStartTimeAvailability();
            this.unwatchStartTimeAvailability = null;

            this.finalForm.change('startTime', null);
            this.availableDateTimes = availableDateTimes;
        },

        updateItemsAvailability(items, availableItems) {
            const itemsCount = items.length;

            if (itemsCount > availableItems.count) {
                this.unwatchItemsAvailability();
                this.unwatchItemsAvailability = null;

                this.finalForm.change(
                    'itemIds',
                    items.filter(({ id }) => availableItems.itemIds.includes(id))
                );

                this.forceUpdateItemsParams();
            }
        },

        validStartDate(date, isValid) {
            return isValid ? undefined : this.t('ri.reservations.create.invalid_start_date', { date });
        },

        validStartDateTime(datetime, isValid) {
            return isValid ? undefined : this.t('ri.reservations.create.invalid_start_datetime', { datetime });
        },

        validItems(items = [], availableItems = []) {
            const itemsTotal = items.length;
            const unavailableCount = itemsTotal - availableItems.count;

            // prettier-ignore
            return unavailableCount === 0
                ? undefined
                : this.t(
                    'ri.reservations.create.invalid_items',
                    {
                        unavailable: unavailableCount,
                        total: itemsTotal,
                        riType: this.t('ri.item', itemsTotal),
                    },
                    unavailableCount
                );
        },

        initializeWatchers() {
            this.$watch('startDateIncomplete', value => {
                if (value) {
                    this.itemsDisabled = true;
                }
            });

            // total
            this.$watch(
                () => [this.formValues.qty, this.formValues.duration],
                (val, oldVal) => {
                    if (isEqual(val, oldVal)) return;

                    const [qty, duration] = val;
                    const total = qty * duration * this.rentableItem?.timeslot?.rate;

                    this.finalForm.change('total', isNaN(total) ? null : total);
                },
                {
                    immediate: true,
                }
            );

            //rental data
            this.$watch(
                () => [this.formValues.start, this.formValues.qty],
                () => {
                    if (this.durationDisabled) {
                        this.resetDuration();
                    }
                }
            );

            this.$watch('formValues.start', async start => {
                const { startTime } = this.formValues;

                if (start) {
                    await this.fetchAvailableDateTimes();

                    if (this.hasTimeInput) {
                        if (startTime) {
                            const endDate = this.getAvailableDateTimeByStart(startTime);

                            if (endDate) {
                                this.updateEndDate({ startTime });
                            } else {
                                this.finalForm.change('startTime', null);
                            }
                        } else {
                            this.setItemsParams({
                                availableFrom: this.getFormattedDate(start),
                                availableTo: this.getFormattedDate(moment.utc(start).endOf('day')),
                            });
                        }
                    } else {
                        const newStartTime = this.availableDateTimes[0].start;

                        this.finalForm.change('startTime', newStartTime);
                        this.updateEndDate({ startTime: newStartTime });
                    }
                } else {
                    if (!startTime) {
                        this.setItemsParams({ availableFrom: null, availableTo: null });
                    }

                    // in case date field was cleared programmatically
                    if (this.$refs.dateTimeInput.$refs.input.dateValue) {
                        this.$refs.dateTimeInput.$refs.input.dateValue = undefined;
                    }

                    this.availableDateTimes = [];
                }

                if (!this.unwatchStartAvailability) {
                    this.unwatchStartAvailability = this.watchStartAvailability();
                }
            });

            this.$watch('formValues.startTime', (startTime, oldStartTime) => {
                const dateChanged = startTime && oldStartTime && !moment(startTime).isSame(oldStartTime, 'day');

                if (!dateChanged) {
                    this.updateEndDate({ startTime });
                }

                if (!startTime) {
                    this.$refs.dateTimeInput.$refs.input.timeValue = undefined;
                }

                if (!this.unwatchStartTimeAvailability) {
                    this.unwatchStartTimeAvailability = this.watchStartTimeAvailability();
                }
            });

            this.$watch(
                'formValues.itemIds',
                (itemIds, oldItemIds) => {
                    if (isEqual(itemIds, oldItemIds)) return;

                    if (!this.unwatchItemsAvailability) {
                        this.unwatchItemsAvailability = this.watchItemsAvailability();
                    }

                    this.finalForm.change('qty', itemIds.length);
                },
                {
                    deep: true,
                }
            );
            this.$watch('formValues.end', end => {
                let availableFrom = null;
                let availableTo = null;
                const { start, startTime } = this.formValues;

                if (end) {
                    availableFrom = startTime;
                    availableTo = end;
                } else {
                    if (this.hasTimeInput && start) {
                        availableFrom = this.getFormattedDate(start);
                        availableTo = this.getFormattedDate(moment.utc(start).endOf('day'));
                    }
                }

                this.setItemsParams({ availableFrom, availableTo });
            });

            this.$watch('formValues.qty', () => {
                if (!this.formValues.start) {
                    this.fetchAvailableDates();
                } else {
                    this.fetchAvailableDateTimes();
                }
            });

            this.$watch('formValues.duration', async duration => {
                const { start, startTime } = this.formValues;

                if (!start) {
                    return;
                }

                this.endDateReduced = false;

                await this.fetchAvailableDateTimes();

                const selectedStartTimeExist = this.getAvailableDateTimeByStart(startTime);

                if (selectedStartTimeExist) {
                    this.updateEndDate({ startTime, duration });
                } else {
                    this.updateEndDate({ duration });
                }
            });

            this.$watch(
                'сalendarActiveMonthYear',
                debounce(({ month, year }) => {
                    const date = { year, month, day: 1 };

                    this.fetchAvailableDates({
                        start: this.getFormattedDate(date),
                    });
                }, 300),
                {
                    deep: true,
                }
            );

            // availability watchers
            this.unwatchStartAvailability = this.watchStartAvailability();
            this.unwatchStartTimeAvailability = this.watchStartTimeAvailability();
            this.unwatchItemsAvailability = this.watchItemsAvailability();
        },

        watchStartAvailability() {
            return this.$watch('formValues.start', start => {
                this.finalForm.change('startAvailability', start);
            });
        },

        watchStartTimeAvailability() {
            return this.$watch('formValues.startTime', startTime => {
                this.finalForm.change('startTimeAvailability', startTime);
            });
        },

        updateStartDate(value) {
            this.finalForm.change('start', value);
        },

        watchItemsAvailability() {
            return this.$watch(
                'formValues.itemIds',
                (itemIds, oldItemIds) => {
                    if (isEqual(itemIds, oldItemIds)) return;

                    this.finalForm.change('itemsAvailability', itemIds);
                },
                {
                    deep: true,
                }
            );
        },

        isStartAvailabilityErrorVisible(props) {
            return (
                props.submitErrors?.startAvailability &&
                !props.dirtyFieldsSinceLastSubmit?.startAvailability &&
                !props.dirtyFieldsSinceLastSubmit?.itemIds
            );
        },

        isStartTimeAvailabilityErrorVisible(props) {
            return (
                props.submitErrors?.startTimeAvailability &&
                !props.dirtyFieldsSinceLastSubmit?.startTimeAvailability &&
                !props.dirtyFieldsSinceLastSubmit?.itemIds
            );
        },

        isItemsVisibilityErrorVisible(props) {
            return props.submitErrors?.itemsAvailability && !props.dirtyFieldsSinceLastSubmit?.itemsAvailability;
        },

        getTimeslotLabel(count = 1) {
            return this.t(`ri.periods.${this.riTimeslot.toLowerCase()}`, count);
        },

        forceUpdateItemsParams() {
            this.itemsRequestParams = { ...this.itemsRequestParams };
        },
    },
};
</script>
