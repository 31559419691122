<template>
    <FinalField
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div :class="['form-field', $attrs['class']]">
                <label
                    v-if="label"
                    class="label"
                >{{ label }}</label
                >

                <DateTimeInput
                    ref="input"
                    :model-value="props.value"
                    v-bind="inputAttrs"
                    @update:modelValue="props.change"
                    @updateMonthYear="val => $emit('updateMonthYear', val)"
                    @date-open="$emit('date-open')"
                    @date-close="$emit('date-close')"
                    @update-date="val => $emit('update-date', val)"
                />

                <FieldError
                    v-if="!suppressError"
                    :name="name"
                />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { omit } from 'lodash-es';
import { FinalField } from 'vue-final-form';
import DateTimeInput from '@/components/ui/DateTimeInput';
import FieldError from '@/components/form/FieldError';

export default {
    components: {
        DateTimeInput,
        FinalField,
        FieldError,
    },

    inheritAttrs: false,

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        suppressError: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['updateMonthYear', 'date-open', 'update-date', 'date-close'],

    computed: {
        inputAttrs() {
            return omit(this.$attrs, 'class');
        },
    },

    methods: {
        clear() {
            this.$refs.input.clear();
        },
    },
};
</script>
